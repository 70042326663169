import axios from '@axios'
import { $api } from '@apiConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchTasks(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get($api.app.todo)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axios
          .post($api.app.todo, taskData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${$api.app.todo}/${task.id}`, task)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${$api.app.todo}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
